<template>
    <div>
        <a-modal :visible="modal.visible" :footer="null" :centered="true" @cancel="onClose">
        <bh-loading :show="loading" />
            <div v-if="modal.type == 'field'">
                <h5>{{edit ? 'Edit Custom Field' : 'Add Custom Field'}}</h5>
                <a-form-model style="margin-top:24px" :model="newField" ref="newField">
                    <a-row :gutter="16">
                        <a-col :span="24">
							<a-form-model-item label="Name" required prop="name" :rules="req('Please enter the Name')">
								<a-input placeholder="Name" v-model="newField.name" size="large"></a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="24" class="mb-4">
							<a-checkbox v-model="newField.required">Required</a-checkbox>
						</a-col>
						<a-col :span="24">
							<a-form-model-item prop="type" label="Field Type" required :rules="req('Please select a Field Type')">
								<a-select @change="typeSelected" v-model="newField.type" size="large">
									<a-select-option v-for="t in types" :key="t.value">{{ t.label }}</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="24" v-if="newField.type == 'multipleChoice'">
							<a-form-model-item>
								<a-checkbox @change="allowMultiple" v-model="newField.multipleAnswer">Allow Multiple Answers</a-checkbox>
							</a-form-model-item>
						</a-col>
						<a-col :span="24" v-if="newField.type === 'multipleChoice' || newField.type === 'checkbox'">
							<a-form-model-item v-for="(option, optionI) in newField.options" :key="optionI"
								:label="`Option #${optionI + 1}`">
								<div class="dF aC">
									<a-input :placeholder="`Option #${optionI + 1}`" v-model="newField.options[optionI]"></a-input>
									<a-icon v-if="newField.options.length - 1 == optionI" @click="addOption" class="ml-3"
										style="cursor:pointer; font-size:20px; color:var(--orange)" type="plus-circle" />
									<a-icon @click="removeOption(optionI)"
										v-if="newField.options.length - 1 != optionI && (optionI != 0 || newField.options.length != 2)"
										type="minus-circle" class="ml-3"
										style="cursor:pointer; font-size:20px; color:var(--orange)" />
								</div>
							</a-form-model-item>
						</a-col>

						<a-col :span="24" v-if="newField.type == 'text'">
							<a-form-model-item prop="defaultAnswer" label="Default Value">
								<a-input v-model="newField.defaultAnswer" size="large"
									placeholder="Enter the Default Value"></a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="24" v-else-if="newField.type == 'number'">
							<a-form-model-item prop="defaultAnswer" label="Default Value">
								<a-input type="number" v-model="newField.defaultAnswer" size="large"
									placeholder="Enter the Default Value" :default-value="'0'"></a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="24" v-else-if="newField.type === 'multipleChoice' || newField.type === 'checkbox'">
							<a-form-model-item prop="defaultAnswer" label="Default Value">
								<a-select v-model="newField.defaultAnswer" size="large" placeholder="Select a Default Value"
									:mode="newField.type == 'checkbox' ? '' : newField.type == 'multipleChoice' && newField.multipleAnswer ? 'multiple' : ''">
									<a-select-option v-for="(option, optionI) in filterOptions(newField.options)" :key="optionI"
										:value="option">{{ option }}</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="24" v-else-if="newField.type === 'date'">
							<a-form-model-item prop="defaultAnswer" label="Default Value">
								<a-date-picker style="width:100%" v-model="newField.defaultAnswer" size="large"
									placeholder="Select a Default Value" />
							</a-form-model-item>
						</a-col>
                        <a-col :span="24">
                            <div class="w-full" style="text-align:right">
                                <!-- <div class="dF"> -->
                                    <a-button size="large" class="cancel-button" @click="onClose">CANCEL</a-button>
                                    <a-button class="ml-3" size="large" type="primary" @click="submit">{{edit ? 'SAVE':'ADD'}}</a-button>
                                <!-- </div> -->
                            </div>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div v-if="modal.type == 'premium'">
                <h5>{{edit ? 'Edit Premium' : 'Add Premium'}}</h5>
                <a-form-model style="margin-top:24px" :model="newPremium" ref="newPremium">
                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-model-item label="Premium Name" prop="name" :rules="req('Please enter the Premium Name')">
                                <a-input placeholder="Premium Name" v-model="newPremium.name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="Premium Value" prop="value">
                                <!-- <a-input prefix="$" placeholder="Premium Value" v-model="newPremium.value"></a-input> -->
                                <a-input-number style="width:100%" v-model="newPremium.value" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Premium Value" >
                                </a-input-number>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
                <div class="dF jE">
                    <a-button @click="onClose" size="large" class="cancel-button">CANCEL</a-button>
                    <a-button @click="submitPremium" size="large" type="primary" class="ml-3">{{edit ? 'SAVE':'ADD'}}</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import moment from 'moment'
export default {
    components:{bhLoading},
    data() {
        return {
            loading:false,
            newField:{
                name:'',
                type:'text',
				required: false,
                multipleAnswer:false,
                defaultAnswer:'',
                options:[''],
                id:0,
                date:0
            },
            newPremium:{
                name:'',
                value:0,
                id:'',
                date:0,
                user:{}
            },
			types: [
				{ value: 'text', label: 'Text' },
				{ value: 'number', label: 'Number' },
				{ value: 'checkbox', label: 'Check Box' },
				{ value: 'multipleChoice', label: 'Multiple Choice' },
				{ value: 'date', label: 'Date (MM/DD/YYYY)' }
			],
        }
    },
    watch:{
        edit:{
            handler(val) {
                if (val) {
                    let type = this.$store.state.condoUnits.modal.type
                    let obj = JSON.parse(JSON.stringify(this.$store.state.condoUnits.modal.obj))
                    if (type == 'field') {
                        this.newField = obj
                    } else if (type == 'premium') {
                        this.newPremium = obj
                    }
                }
            }
        }
    },
    computed:{
        edit() {
            return this.$store.state.condoUnits.modal.edit
        },
        premiums() {
            if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options && this.$store.state.condoUnits.allSettings.app.options.premiums && this.$store.state.condoUnits.allSettings.app.options.premiums.length) {
                return this.$store.state.condoUnits.allSettings.app.options.premiums
            }
			return [
                    {
                        name:'Floor Premium',
                        value:'',
                        id:'floorPremium'
                    },
                    {
                        name:'View Premium',
                        value:'',
                        id:'viewPremium'
                    },
                    {
                        name:'Balcony Premium',
                        value:'',
                        id:'balconyPremium'
                    },
                    {
                        name:'Terrace Premium',
                        value:'',
                        id:'terracePremium'
                    },
                ]
        },
        customFields() {
            if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options && this.$store.state.condoUnits.allSettings.app.options.customFields) return this.$store.state.condoUnits.allSettings.app.options.customFields
			return []
        },
        modal(){
            return this.$store.state.condoUnits.modal
        }
    },
    methods:{
        submitPremium() {
            if(this.edit) this.editPremium()
            else this.addPremium()
        },
        addPremium() {
            this.$refs.newPremium.validate(valid => {
                if (valid) {
                    let sendObj = {}
                    sendObj.name = this.newPremium.name
                    sendObj.value = parseInt(this.newPremium.value)
                    sendObj.date = Date.now()
                    sendObj.id = Date.now().toString()
                    sendObj.user = {
                        firstName:this.$store.state.user.user.firstName,
                        lastName:this.$store.state.user.user.lastName,
                        id:this.$store.state.user.user.id
                    }

                    let settings = JSON.parse(JSON.stringify(this.$store.state.condoUnits.allSettings.app.options))
                    let sendArray = []
                    if (settings.premiums && settings.premiums.length) {
                        let pre = settings.premiums
                        pre.push(sendObj)
                        sendArray = pre
                    } else {
                        let pre = this.premiums
                        pre.push(sendObj)
                        sendArray = pre
                    }
                    settings.premiums = sendArray
                    this.loading = true
                    this.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                        this.$store.commit('UPDATE_SETTINGS', data)
                        this.loading= false
                        this.onClose()
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            })
        },
        editPremium() {
            this.$refs.newPremium.validate(valid =>{
                if (valid) {
                    let sendObj = {}
                    sendObj.name = this.newPremium.name
                    sendObj.value = parseInt(this.newPremium.value)
                    if (this.newPremium.hasOwnProperty('date')){
                        sendObj.date = this.newPremium.date
                    } else {
                        sendObj.date = Date.now()
                    }
                    sendObj.id = this.newPremium.id
                    sendObj.user = {
                        firstName:this.$store.state.user.user.firstName,
                        lastName:this.$store.state.user.user.lastName,
                        id:this.$store.state.user.user.id
                    }

                    let settings = this.$store.state.condoUnits.allSettings.app.options
                    if (settings.premiums && settings.premiums.length) {
                        let findIndex = settings.premiums.findIndex(x => x.id == sendObj.id)
                        if (findIndex != -1) {
                            settings.premiums[findIndex] = sendObj
                            this.loading = true
                            this.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.loading= false
                                this.onClose()
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    } else {
						let settings = {};
						let pre = this.premiums
						settings.premiums = pre
						let findIndex = settings.premiums.findIndex(x => x.id == sendObj.id)
                        if (findIndex != -1) {
                            settings.premiums[findIndex] = sendObj
                            this.loading = true
                            this.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.loading= false
                                this.onClose()
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
					}
                }
            })
        },
        submit() {
            if (this.edit) this.editField()
            else this.addField()
        },
        editField() {

            this.$refs.newField.validate(valid => {
                if (valid) {
                    let sendObj = {}
                    if (this.newField.type == 'text' || this.newField.type == 'number' || this.newField.type == 'date') {
                        sendObj.name = this.newField.name
                        sendObj.type = this.newField.type
                        sendObj.defaultAnswer = this.newField.defaultAnswer
                        sendObj.id = this.newField.id
                        sendObj.user = {
                            firstName:this.$store.state.user.user.firstName,
                            lastName:this.$store.state.user.user.lastName,
                            id:this.$store.state.user.user.id
                        }
                        sendObj.date = this.newField.date
                    } else if (this.newField.type == 'multipleChoice' || this.newField.type === 'checkbox') {
                        let validSend = true
                        this.newField.options.forEach(x => {
                            if (x == '') {
                                validSend = false
                                return this.$message.error('Please fill in all the options!')
                            }
                        })
                        if (validSend) {
                            sendObj = this.newField
                            sendObj.user = {
                                firstName:this.$store.state.user.user.firstName,
                                lastName:this.$store.state.user.user.lastName,
                                id:this.$store.state.user.user.id
                            }
                            sendObj.id = this.newField.id
                            sendObj.date = this.newField.date
                        }
                    }
                    let settings = JSON.parse(JSON.stringify(this.$store.state.condoUnits.allSettings.app.options))
                    if (settings.customFields && settings.customFields.length) {
                        let findIndex = settings.customFields.findIndex(x => x.id == sendObj.id)
                        if (findIndex != -1) {
                            settings.customFields[findIndex] = sendObj
                            this.loading = true
                            this.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.loading= false
                                this.onClose()
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    }
                }
            })
        },
        addField() {
            this.$refs.newField.validate(valid => {
                if (valid) {
                    let sendObj = {}
                    if (this.newField.type == 'text' || this.newField.type == 'number' || this.newField.type == 'date') {
                        sendObj.name = this.newField.name
                        sendObj.type = this.newField.type
                        sendObj.required = this.newField.required
                        sendObj.defaultAnswer = this.newField.defaultAnswer
                        sendObj.id = Date.now().toString()
                        sendObj.user = {
                            firstName:this.$store.state.user.user.firstName,
                            lastName:this.$store.state.user.user.lastName,
                            id:this.$store.state.user.user.id
                        }
                        sendObj.date = Date.now()
                    } else if (this.newField.type == 'multipleChoice' || this.newField.type === 'checkbox') {
                        let validSend = true
                        this.newField.options.forEach(x => {
                            if (x == '') {
                                validSend = false
                                return this.$message.error('Please fill in all the options!')
                            }
                        })
                        if (validSend) {
                            sendObj = this.newField
							sendObj.required = this.newField.required
                            sendObj.user = {
                                firstName:this.$store.state.user.user.firstName,
                                lastName:this.$store.state.user.user.lastName,
                                id:this.$store.state.user.user.id
                            }
                            sendObj.id = Date.now().toString()
                            sendObj.date = Date.now()
                        }
                    }
                    if (!this.customFields.length && Object.keys(sendObj).length != 0 && this.premiums.length == 4) { // fields && premiums dont exist
                        this.loading = true
                        this.$api.post(`/settings/:instance/condounits`, {options:{customFields:[sendObj]}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if(this.customFields.length && Object.keys(sendObj).length != 0 && this.premiums.length == 4) { // fields exist but premiums dont
                        let fields = JSON.parse(JSON.stringify(this.customFields))
                        fields.push(sendObj)
                        this.loading = true
                        this.$api.post(`/settings/:instance/condounits`, {options:{customFields:fields}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if (!this.customFields.length && Object.keys(sendObj).length != 0 && this.premiums.length > 4) { // premiums exist but fields dont
                        this.loading = true
                        this.$api.post(`/settings/:instance/condounits`, {options:{customFields:[sendObj], premiums: this.premiums}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.loading= false
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if (this.customFields.length && Object.keys(sendObj).length != 0 && this.premiums.length > 4) { // both exist
                        let fields = JSON.parse(JSON.stringify(this.customFields))
                        fields.push(sendObj)
                        this.loading = true
                        this.$api.post(`/settings/:instance/condounits`, {options:{customFields:fields, premiums:this.premiums}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }

                }
            })
        },
        filterOptions(arr) {
			return arr.filter(x => x != '')
		},
        allowMultiple(e) {
            if(e.target.checked) {
                this.newField.defaultAnswer = []
            } else {
                this.newField.defaultAnswer = ''
            }
        },
        changeType(e) {
            this.newField.options = ['']
            this.newField.defaultAnswer = ''
        },
        addOption() {
            let pushNew = true
            this.newField.options.forEach(x => {
                if (x == '' || !x.trim()) {
                    pushNew = false
                    return this.$message.error('Please fill in all the options!')
                }
            })
            if (pushNew) {
                this.newField.options.push('')
            }
        },
        removeOption(index) {
            this.newField.options.splice(index,1)
        },
        req:msg=>({required:true,message:msg}),
        onClose() {
            if (this.modal.type == 'field') {
                this.newField = {
                    name:'',
                    type:'text',
					required: false,
                    multipleAnswer:false,
                    defaultAnswer:'',
                    options:[''],
					id: 0,
					date: 0
                }
                this.$refs.newField.resetFields();
            } else if (this.modal.type == 'premium') {
                this.newPremium = {
                    name:'',
                    value:0,
                    id:'',
                    date:0,
                    user:{}
                }
                this.$refs.newPremium.resetFields();
            }
            this.$store.commit('CLOSE_MODAL')
        },
		typeSelected(e) {
			if (e == 'text') {
				this.newField.defaultAnswer = ''
			} else if (e == 'number') {
				this.newField.defaultAnswer = 0
			} else if (e == 'checkbox' || e == 'multipleChoice') {
				this.newField.defaultAnswer = ''
				this.newField.options = ['']
			} else if (e == 'date') {
				this.newField.defaultAnswer = moment(Date.now())
			}
		},
    }
}
</script>

<style>

.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
